import { HiOutlineRefresh } from 'react-icons/hi';
import { GrClose } from 'react-icons/gr';
import { ChatHeaderContainer, AvatarContainer, AvatarImage, Title, IconsContainer, Icon } from './header.styled';

const ChatHeader = ({ settings, setChatActive, endChat, isRestart, showResetButton }) => {
  return (
    <ChatHeaderContainer>
      <AvatarContainer>
        {settings?.avatar !== 'disabled' && <AvatarImage alt="avatar" src={settings?.avatar} />}
        <Title>{settings?.title}</Title>
      </AvatarContainer>
      <IconsContainer>
        {!isRestart && showResetButton && (
          <Icon onClick={endChat} title="new chat">
            <HiOutlineRefresh />
          </Icon>
        )}

        <Icon onClick={() => setChatActive(false)} title="minimize">
          <GrClose />
        </Icon>
      </IconsContainer>
    </ChatHeaderContainer>
  );
};

export default ChatHeader;
