import { MdSupportAgent } from 'react-icons/md';
import { useEffect, useRef } from 'react';
import Markdown from 'markdown-to-jsx';

import ChatLoading from '../../assets/chat-loading';
import RenderLinks from '../../assets/render-links';

import {
  AgentAvatarContainer,
  UserMessageContainer,
  BotMessageContainer,
  BotAvatarContainer,
  BodyContainer,
  AgentMessage,
  UserMessage,
  BotMessage,
  Avatar,
} from './body.styled';

const ChatBody = ({ settings, conversation, showLoading, showInitialMessage, showInitialMessage2, conversationCount, chatActive }) => {
  const chatBoxRef = useRef();

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [conversation, showLoading]);

  return (
    <BodyContainer ref={chatBoxRef}>
      {conversationCount === 0 && showInitialMessage && chatActive && settings?.welcomeMessage && (
        <BotMessageContainer>
          {(settings?.avatar !== 'disabled' || settings?.avatar === '') && (
            <BotAvatarContainer>
              <Avatar src={settings?.avatar} alt="avatar" />
            </BotAvatarContainer>
          )}
          <BotMessage>{settings?.welcomeMessage}</BotMessage>
        </BotMessageContainer>
      )}
      {conversationCount === 0 && showInitialMessage2 && chatActive && settings?.welcomeMessage2 && (
        <BotMessageContainer>
          {(settings?.avatar !== 'disabled' || settings?.avatar === '') && (
            <BotAvatarContainer>
              <Avatar src={settings?.avatar} alt="avatar" />
            </BotAvatarContainer>
          )}

          <BotMessage>{settings?.welcomeMessage2}</BotMessage>
        </BotMessageContainer>
      )}
      {conversation?.map((line, index) => {
        if (line.sender === 'MODEL' && line.message) {
          return (
            <BotMessageContainer key={index}>
              {(settings?.avatar !== 'disabled' || settings?.avatar === '') && (
                <BotAvatarContainer>
                  <Avatar src={settings?.avatar} alt="avatar" />
                </BotAvatarContainer>
              )}
              <BotMessage noAvatar={settings?.avatar === 'disabled'}>
                <Markdown
                  options={{
                    overrides: {
                      a: { props: { target: '_blank', style: { color: '#3c82f6', fontWeight: '400' } } },
                      th: { props: { style: { fontSize: '14px', fontWeight: '500', border: '1px solid lightgray', borderCollapse: 'collapse' } } },
                      td: {
                        props: { style: { fontSize: '14px', fontWeight: '300', padding: '3px', border: '1px solid lightgray', borderCollapse: 'collapse' } },
                      },
                      table: { props: { style: { marginRight: '10px', marginBottom: '10px', border: '1px solid lightgray', borderCollapse: 'collapse' } } },
                      ol: { props: { style: { paddingInlineStart: '20px' } } },
                      ul: { props: { style: { paddingInlineStart: '20px' } } },
                      p: { props: { style: { marginBottom: '20px', marginTop: '0px' } } },
                    },
                  }}
                >
                  {line.message}
                </Markdown>
              </BotMessage>
            </BotMessageContainer>
          );
        }

        if (line.sender === 'AGENT' && line.message) {
          return (
            <BotMessageContainer key={index}>
              {(settings?.avatar !== 'disabled' || settings?.avatar === '') && (
                <AgentAvatarContainer>
                  <MdSupportAgent />
                </AgentAvatarContainer>
              )}

              <AgentMessage noAvatar={settings?.avatar === 'disabled'}>
                <span>
                  <RenderLinks text={line.message} />
                </span>
              </AgentMessage>
            </BotMessageContainer>
          );
        }

        if (line.sender === 'USER' && line.message) {
          return (
            <UserMessageContainer key={index}>
              <UserMessage>{line.message}</UserMessage>
            </UserMessageContainer>
          );
        }

        return null;
      })}
      {showLoading && (
        <BotMessageContainer>
          {(settings?.avatar !== 'disabled' || settings?.avatar === '') && (
            <BotAvatarContainer>
              <Avatar src={settings?.avatar} alt="avatar" />
            </BotAvatarContainer>
          )}
          <BotMessage>
            <ChatLoading />
          </BotMessage>
        </BotMessageContainer>
      )}
    </BodyContainer>
  );
};

export default ChatBody;
