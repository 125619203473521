import styled from 'styled-components/macro';
import { MdOutlineReportGmailerrorred } from 'react-icons/md';

export const LoaderIcon = styled.div`
  right: ${({ theme }) => theme.right};
  left: ${({ theme }) => theme.left};
  position: fixed;
  width: 64px;
  height: 64px;
  border: 2px solid red;
  bottom: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 60px;
  font-weight: 700;
  background-color: #ed1943;
`;

const Error = () => {
  return (
    <LoaderIcon>
      <MdOutlineReportGmailerrorred />
    </LoaderIcon>
  );
};

export default Error;
