import styled from 'styled-components/macro';

export const ChatHeaderContainer = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  border-radius: 10px 10px 0px 0px;
  background-color: ${({ theme }) => theme.themeColor};
  z-index: 5000;

  @media (max-width: 480px) {
    border-radius: 0px;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AvatarImage = styled.img`
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  overflow: hidden !important;
`;

export const Title = styled.div`
  color: white;
  font-size: 18px;
  margin-left: 10px;
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  font-size: 22px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s ease-out;
  color: white;

  &:hover {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
