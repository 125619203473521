export const playError = () => {
  const notification = new Audio('https://chatbuildx-bb-ui.vercel.app/static/media/error_message_notification.mp3');

  var notif = notification.play();

  if (notif !== undefined) {
    notif
      .then((_) => {
        // notification sounded
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export const playNotification = () => {
  const notification = new Audio('https://app.chatbuildx.com/static/media/new_message_notification.mp3');

  var notif = notification.play();

  if (notif !== undefined) {
    notif
      .then((_) => {
        // notification sounded
      })
      .catch((error) => {
        console.log(error);
      });
  }
};
