import React from 'react';

const convertMarkdownToHtmlLink = (text) => {
  // Regular expression to find [display_name]( URL link ) format, allowing spaces within the parentheses
  const pattern = /\[([^\]]+)\]\(\s*(https?:\/\/[^\s)]+(?:\s+[^\s)]+)*)\s*\)/g;
  // Replace the markdown link with HTML link
  const htmlText = text.replace(pattern, function (match, displayName, url) {
    // Trim spaces from the URL for safety
    const cleanedUrl = url.trim();
    return `<a href="${cleanedUrl}" target="_blank" rel="noopener noreferrer">${displayName}</a>`;
  });

  return htmlText;
};

function extractQuotedTextAndRemaining(str) {
  const regex = /['"]([^'"]+)['"]/g;
  let resultArray = [];
  let match;
  let lastIndex = 0;

  // extract link enclosed in quotation mark
  while ((match = regex.exec(str)) !== null) {
    resultArray.push(match[1]);
    lastIndex = regex.lastIndex;
  }

  // Add any remaining text after the last quote
  if (lastIndex < str.length) {
    resultArray.push(str.substring(lastIndex));
  }

  return resultArray;
}

const RenderLinks = ({ text }) => {
  // convert the markdown to html link
  const convertedParagraph = convertMarkdownToHtmlLink(text);

  const linkRegex = /(https?:\/\/[^\s]+)/g;

  const renderWithLinks = () => {
    // splitting word for word to handle non markdown format html link
    const parts = convertedParagraph.split(' ');

    let wordsArray = parts.map((part) => {
      // checking for https links
      if (part.match(linkRegex)) {
        // ignores links that starts with href or noreferrer, this means it was updated using the convertMarkdownToHtmlLink function
        if (part.startsWith('href') || part.startsWith('noreferrer')) {
          return `${part} `;
        }

        // handles links that is enclosed in single and double quotes

        if (part.startsWith("'https://") || part.startsWith('"https://')) {
          let link = extractQuotedTextAndRemaining(part);
          return `<a href="${link[0]}" target="_blank" rel="noopener noreferrer">${link[0]}</a>${link[1]} `;
        }

        // handles links that ends with period

        if (part.slice(-1) === '.' || part.slice(-1) === ',' || part.slice(-1) === ':' || part.slice(-1) === ';') {
          return `<a href="${part.substring(0, part.length - 1)}" target="_blank" rel="noopener noreferrer">${part.substring(
            0,
            part.length - 1
          )}</a>${part.slice(-1)} `;
        }

        return `<a href="${part.substring(0, part.length - 1)}" target="_blank" rel="noopener noreferrer">${part}</a> `;
      } else {
        return `${part} `;
      }
    });

    return wordsArray.join('').replace(/\n/g, '<br />');
  };

  return <span dangerouslySetInnerHTML={{ __html: renderWithLinks() }} />;
};

export default RenderLinks;
