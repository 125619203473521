export const sortConversation = (data) => {
  const sortedData = data.data.sort((a, b) => {
    if (a.id > b.id) return 1;
    if (b.id > a.id) return -1;
    return 0;
  });

  return sortedData;
};

export const sanitize = (string) => {
  const reg = /<\/?[^>]+(>|$)/g;
  return string.replace(reg, '');
};
