import styled from 'styled-components/macro';

export const ChatContainer = styled.div`
  display: block;
  position: fixed;
  width: 370px;
  max-height: ${(props) => (props.chatActive ? '590px' : '0px')};
  overflow: ${(props) => (props.chatActive ? 'none' : 'hidden')};
  border-radius: 10px;
  right: ${({ theme }) => theme.right};
  left: ${({ theme }) => theme.left};
  background-color: aliceblue;
  bottom: 100px;
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 1px 1px 20px rgb(155, 155, 155);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  z-index: 5000;

  @media (max-height: 690px) {
    max-height: ${(props) => (props.chatActive ? 'calc(100vh - 40px)' : '0px')};
    bottom: 20px;
  }

  @media (max-height: 600px) {
    max-height: ${(props) => (props.chatActive ? 'calc(100vh - 20px)' : '0px')};
    bottom: 10px;
  }

  @media (max-height: 500px) {
    max-height: ${(props) => (props.chatActive ? '100vh' : '0px')};
    bottom: 0px;
  }

  @media (max-width: 480px) {
    max-height: ${(props) => (props.chatActive ? 'calc(100vh - 90px)' : '0px')};
    width: 100%;
    right: 0px;
    left: 0px;
    bottom: 0px;
    border-radius: 0px;
  }
`;
