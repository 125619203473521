import { HiOutlineRefresh } from 'react-icons/hi';

export const fourZeroOne = (setNotification, endChat) => {
  return (
    <span>
      Chat session has expired. Click
      <span
        style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: '16px' }}
        onClick={() => {
          setNotification('Creating new chat session...');
          endChat();
        }}
      >
        {' '}
        <HiOutlineRefresh />{' '}
      </span>
      to restart.
    </span>
  );
};

export const fourZeroFour = (setNotification, sendMessageMutation, messageBucket) => {
  return (
    <span>
      Internet connection lost. Click{' '}
      <span
        style={{ cursor: 'pointer', fontWeight: 'bold' }}
        onClick={() => {
          setNotification('Connecting...');
          sendMessageMutation.mutate(messageBucket[0]);
        }}
      >
        HERE
      </span>{' '}
      to retry.
    </span>
  );
};

export const fiveHundredPlus = (setNotification, sendMessageMutation, unsentMessageBucket) => {
  return (
    <span>
      Server Error. Click{' '}
      <span
        style={{ cursor: 'pointer', fontWeight: 'bold' }}
        onClick={() => {
          setNotification('Resending message...');
          sendMessageMutation.mutate(unsentMessageBucket[0]);
        }}
      >
        HERE
      </span>{' '}
      to resend the message.
    </span>
  );
};

export const startConversationError = (setNotification, startConversationMutation, uuid) => {
  return (
    <span>
      Error starting a new conversation. Click{' '}
      <span
        style={{ cursor: 'pointer', fontWeight: 'bold' }}
        onClick={() => {
          setNotification('Restarting chat...');
          startConversationMutation.mutate(uuid());
        }}
      >
        HERE
      </span>{' '}
      to try again.
    </span>
  );
};
