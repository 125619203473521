import styled from 'styled-components/macro';

export const ChatFooterContainer = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 5000;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`;

export const ReplyBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  border-radius: 0px 0px 10px 10px;
  padding: 0px 10px;
  background-color: rgb(237, 235, 235);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`;

export const InputField = styled.input`
  all: unset !important;
  border: none !important;
  outline: none !important;
  background-color: transparent !important;
  margin-left: 5px !important;
  padding: 5px !important;
  width: 80% !important;
  font-size: 16px !important;
  color: rgb(86, 88, 103) !important;
  font-weight: normal !important;
  letter-spacing: 1px !important;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;

  &:focus {
    border: none !important;
    outline: none !important;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
`;

export const Icon = styled.div`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  margin-right: 15px;
  font-size: 18px;
  color: rgb(10, 10, 10);
  transition: all 0.3s ease-out;

  &:hover {
    border: none;
    outline: none;
  }
`;

export const SendIcon = styled.div`
  cursor: ${(props) => (props.isEmpty ? 'default' : 'pointer')};
  margin-right: 15px;
  font-size: 18px;
  color: rgb(10, 10, 10);
  transition: all 0.3s ease-out;

  &:hover {
    border: none;
    outline: none;
  }
`;

export const EmojiContainer = styled.div`
  position: fixed;
  bottom: 160px;
  right: ${({ theme }) => (theme.positionIsRight ? '80px' : 'none')};
  left: ${({ theme }) => (theme.positionIsRight ? 'none' : '160px')};

  @media (max-width: 480px) {
    bottom: 70px;
    right: 10px;
    left: 10px;
  }
`;

export const PoweredBy = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PoweredByImage = styled.img`
  all: unset !important;
  width: auto !important;
  height: 30px !important;
  overflow: hidden !important;
`;

export const NotificationContainer = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Notification = styled.div`
  color: red;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1px;
  font-weight: 300;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
