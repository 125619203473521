import Axios from 'axios';

const apiRequest = async (payload) => {
  let config = {
    method: payload.method,
    url: `${process.env.REACT_APP_API_URL}/${payload.url}`,
    headers: payload.botId
      ? {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem(`chatBotConversationToken-${payload.botId}`)}`,
        }
      : {
          'Content-Type': 'application/json',
        },
    data: payload.data,
  };

  return Axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export default apiRequest;

export const getPublicIp = async () => {
  try {
    const response = await Axios.get('https://api.ipify.org?format=json');
    return response.data.ip;
  } catch (error) {
    console.error('Failed to fetch public IP:', error);
    return null;
  }
};
