import styled, { keyframes } from 'styled-components/macro';

const transition = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
`;

const DotContainer = styled.p`
  font-size: 30px;
  text-align: center;
`;
const Dot1 = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #666;
  border-radius: 50%;
  margin: 0 5px;
  animation: ${transition} 1.5s infinite;
`;
const Dot2 = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #666;
  border-radius: 50%;
  margin: 0 5px;
  animation: ${transition} 1.5s infinite;
  animation-delay: 0.5s;
`;
const Dot3 = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #666;
  border-radius: 50%;
  margin: 0 5px;
  animation: ${transition} 1.5s infinite;
  animation-delay: 1s;
`;

const ChatLoading = () => {
  return (
    <DotContainer>
      <Dot1></Dot1>
      <Dot2></Dot2>
      <Dot3></Dot3>
    </DotContainer>
  );
};

export default ChatLoading;
